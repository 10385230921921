:root {
  --space-unit: 1em;
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));

  --component-padding: var(--space-sm);
}

@include breakpoint(md) {
  :root {
    --space-unit: 1.25em;
    --component-padding: var(--space-md);
  }
}

.margin-top,
.margin-top--md {
  margin-top: var(--space-md);
}

.margin-top--sm {
  margin-top: var(--space-sm);
}

.margin-top--lg {
  margin-top: var(--space-lg);
}

.margin-top--xl {
  margin-top: var(--space-xl);
}

.margin-top--xxl {
  margin-top: var(--space-xxl);
}

.margin-bottom,
.margin-bottom--md {
  margin-bottom: var(--space-md);
}

.margin-bottom--sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom--lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom--xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom--xxl {
  margin-bottom: var(--space-xxl);
}

@include breakpoint(md) {

  .margin-top,
  .margin-top--md {
    margin-top: var(--space-lg);
  }

  .margin-top--sm {
    margin-top: var(--space-md);
  }

  .margin-top--lg {
    margin-top: var(--space-xl);
  }

  .margin-top--xl {
    margin-top: var(--space-xxl);
  }

  .margin-top--xxl {
    margin-top: var(--space-xxxl);
  }

  .margin-bottom,
  .margin-bottom--md {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom--sm {
    margin-bottom: var(--space-md);
  }

  .margin-bottom--lg {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom--xl {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom--xxl {
    margin-bottom: var(--space-xxxl);
  }
}