.navigationWrapper {
  background-color: #fff;
}

.naviItem {
  color: var(--chakra-colors-customGray-700);
}

.naviItemActive {
  background-color: var(--chakra-colors-customGray-20);
  font-weight: 700;
}

.naviItemActive .navItemIcon {
  color: var(--chakra-colors-green-500);
}

/* Dark mode colors */
[data-theme='dark'] .navigationWrapper {
  background-color: var(--chakra-colors-customGray-800);
}

[data-theme='dark'] .naviItem {
  color: #fff;
}

[data-theme='dark'] .naviItemActive {
  background-color: var(--chakra-colors-customGray-700);
}

[data-theme='dark'] .navItemIcon {
  color: var(--chakra-colors-customGray-400);
}

[data-theme='dark'] .naviItemActive .navItemIcon {
  color: var(--chakra-colors-green-500);
}
