@import './scss/fonts';
@import './scss/variables';
@import './scss/spacing';

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-family: Circular-Book, Helvetica, Arial, sans-serif;
  color: $color-text;
  font-weight: 300;
  background-color: #ffffff;
  line-height: 1.5;

  @media only screen and (max-width: 992px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 13px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.41px;
}

h1 {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 2.5rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

.button {
  $button-background-color: #fff;
  $button-border-color: #c7c7c7;
  $button-border-width: 1px;
  $button-border-radius: 3px;
  $button-box-shadow: 1px 2px 32px 0 rgba(0, 0, 0, 0.1);
  $button-color: inherit;
  $button-disabled-opacity: 0.6;
  $button-font: inherit;
  $button-font-size: 1rem;
  $button-font-weight: 600;
  $button-padding: 0.4em 0.75em;
  $button-padding-large: 1em 2.75em;

  background: $button-background-color;
  border-color: $button-border-color;
  border-radius: $button-border-radius;
  border-style: solid;
  border-width: $button-border-width;
  box-sizing: border-box;
  color: $button-color;
  display: inline-block;
  font: $button-font;
  font-size: $button-font-size;
  margin: 0;
  padding: $button-padding;
  position: relative;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.1s ease;
  // box-shadow: $button-box-shadow;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background-color: $color-grey-lighther;
  }

  &:disabled,
  &.is-disabled {
    cursor: default;
    opacity: $button-disabled-opacity;
  }

  &--primary {
    font-weight: $button-font-weight;
    background-color: $color-primary;
    border-color: $color-primary;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-primary-darker;
      border-color: $color-primary-darker;
    }
  }

  &--green {
    background-color: $color-green;
    border-color: $color-green;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-green-darker;
      border-color: $color-green-darker;
    }
  }

  &--loading {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;

    &:before {
      content: '';
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-top-color: #fff;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5rem;

      animation: button-loader-animation 0.5s linear infinite;
    }
  }

  &--large {
    @include breakpoint(md) {
      padding: $button-padding-large;
    }
    padding: 0.75rem 1.5rem;
  }

  &--wide {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  &--ghost {
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &--with-icon {
    display: flex;
    align-items: center;
  }

  &__icon {
    height: 1.5rem;
    width: 1.5rem;

    &--right {
      margin-left: 0.33rem;
    }
    &--left {
      margin-right: 0.33rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  // &:focus {
  //   // outline: 1px solid $color-primary;
  //   // border-color: $color-primary !important;
  // }
}

.text-center {
  text-align: center;
}

.dropdown-arrow {
  border-radius: 100%;
  font-weight: 700;
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: #424242 transparent transparent;
    border-style: solid;
    border-width: 9px 6px 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }

  &--open:after {
    transform: rotate(-180deg);
  }
}

.toast {
  background: #fff;
  color: #424242;
  border-radius: 0;
  padding: 1rem 2rem;
}

@keyframes button-loader-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
