@import "../../scss/variables";

.login-page {
  display: flex;
  min-height: calc(var(--vh, 1vh) * 100);
  align-items: center;
  justify-content: center;
  flex-flow: column;
  max-width: $container-max-width;
  margin: 0 auto;

  h1 {
    font-weight: bold;
  }
  h3 {
    color: $color-green;
  }

  @include breakpoint(md) {
    flex-flow: row;
  }

  .col {
    height: 100%;
    flex: 100%;
    max-width: 100%;
    padding: 5rem;
    @include breakpoint(md) {
      flex: 50%;
      max-width: 50%;
    }

    &--left {
      display: none;
      @include breakpoint(md) {
        display: block;
      }
    }
  }
}