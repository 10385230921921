@import '../../scss/variables';

body.menu-open {
  #mainMap.mapboxgl-map {
    @include breakpoint(md) {
      max-width: calc(100vw - #{$menu-max-width});
    }
  }
}

#mainMap {
  height: calc(var(--vh, 1vh) * 100) !important;
  width: 100vw;
  max-width: 100vw;
  margin-left: auto;
  // transition: max-width 0.2s ease;

  &.mapboxgl-map {
    font-size: 16px;
  }

  .mapboxgl-ctrl-logo {
    display: none;
  }

  .mapboxgl-ctrl-group {
    box-shadow: none;
    background: none;
    margin: 0 1rem 1rem 0;

    &:empty {
      display: none;
    }

    & > button,
    & > .button {
      display: block;
      background: #fff;
      margin-bottom: 0.5rem;
      height: 3rem;
      width: 3rem;
      border-radius: 0.375rem;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
      padding: 0;
      outline: none;
      box-sizing: border-box;
      background-color: transparent;
      cursor: pointer;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-of-type {
        margin-bottom: 0;
      }

      .mapboxgl-ctrl-icon {
        background-color: #fff;
        background-size: 80%;
      }
    }
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url('/components/Icon/svgs/zoom-in.svg');
    height: 32px;
    width: 32px;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url('/components/Icon/svgs/zoom-out.svg');
    height: 32px;
    width: 32px;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: url('/components/Icon/svgs/control.svg');
    height: 32px;
    width: 32px;
  }

  .mapboxgl-ctrl button:disabled .mapboxgl-ctrl-icon {
    opacity: .25;
  }

  .mapboxgl-ctrl-scale {
    background-color: transparent;
    margin: 1rem 0 0 1rem;
    color: #5a5a5a;
    border-color: #5a5a5a;
  }

  .property-map-marker {
    cursor: pointer;
    color: #333;
    transition: transform 0.4s ease;
    transform: scale(.8);

    &:hover {
      transform: scale(1.2);
      color: #078571;
    }

    &--selected {
      transform: scale(1.6);
      color: #078571;
      &:hover {
        color: #078571;
        transform: scale(1.6);
      }
    }

    &--notselected {
      transform: scale(.8);
      opacity: 0.8;
      color: #333;
    }

    path {
      stroke: #f9f9f9;
      stroke-width: 0.2px;
    }
  }

  .tooltip {
    position: absolute;
    margin: 0.5rem;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 18rem;
    font-size: 0.8rem;
    z-index: 9;
    pointer-events: none;

    &--fixed {
      position: fixed;
    }

    &__feature {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin: 0;
      }

      &__name {
        font-weight: bold;
      }
    }
  }

  .mapboxgl-popup {
    min-width: 20rem;
    width: auto;
    margin-top: 1rem;
    @include breakpoint(md) {
      max-width: 50rem !important;
    }
  }

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    margin-top: 0.5rem;
    padding: 0;

    .green-button {
      width: 100%;
      height: 40px;
      background-color: #078571;
      color: #fff;
      border-radius: 4px;
      text-align: center;
      padding: 10px;
      font-weight: 500;
    }
  }

  .selected-parking-space-popup {
    min-width: 8rem;
    padding: 0;

    .mapboxgl-popup-tip {
      display: block;
    }

    .mapboxgl-popup-content {
      padding: 0;
      margin: 0;
    }

    .popup-header {
      padding: 1rem;
      border-bottom: 1px solid #c7c7c7;
    }

    .popup-body {
      padding: .5rem;
      text-align: center;
    }

    .popup-footer {
      padding: .33rem;
      text-align: center;
      background-color: $color-blue;
      color: #fff;
    }
  }

  .space-marker-label {
    font-size: 0.8rem;
    color: #fff;
    background-color: #181818;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 0;
  }

  .selected-space-popup {
    min-width: 8rem;
    margin: 0;
    .mapboxgl-popup-content {
      margin: 0 0 0.5rem 0;
      border: none;
      border-radius: 3px;
      padding: 0;
      background: inherit;
    }
  }

  .mapboxgl-popup-close-button {
    $positionTop: -1rem;
    $positionRight: -1rem;
    font-size: 2rem;
    text-indent: -99999px;
    border-radius: 100%;
    background-image: url("/components/Icon/pngs/closeBtn.png");
    background-position: center;
    background-size: contain;
    background-color: #fff;
    height: 2rem;
    width: 2rem;
    top: $positionTop;
    right: $positionRight;
    padding: 0;
    transition: all 0.3s ease-in-out;

  }
}
